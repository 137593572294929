import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/notice.css"

const notice6 = () => (
  <Layout>
    <SEO title="Notice" />
    <div className="mainContent">
      <div className="noticePage">
        <div className="content">
          <div className="noticeHeader">공고 / 공지</div>
          <div className="noticeBody">
            <div className="titleArea">
              <div className="title">임시주주총회 소집을 위한 명의개서 정지의 건</div>
              <div className="date">2021-10-29</div>
            </div>
            <div className="textArea">
              <div className="contentsArea">
                <div className="textTitle">주주확정 기준일 및 주식명의개서 정지공고</div>
                <div className="textDesc">
                  <p>
                    상법 제354조 및 당사 정관 제15조에 의거, 임시주주총회 참석을 위한 권리주주를 확정하기 위하여 주식의 명의개서,
                    질권의 등록 및 그 변경과 말소, 신탁재산의 표시 및 말소 등 주주명부 기재사항 변경을 정지함을 공고합니다.
                  </p>
                  <br />
                  <p className="center">- 아&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;래 -</p>
                  <br />
                  <p>
                    1. 내용: 임시주주총회 권리주주 확정
                    <br />
                    2. 기준일: 2021년 11월 15일
                    <br />
                    3. 명의개서 정지기간: 2021년 11월 16일 ~ 11월 29일
                    <br />
                  </p>
                  <br />
                  <br />
                  <p className="footer">
                    2021년 10월 29일
                    <br />
                    주식회사 스테이지파이브 대표이사 서 상 원<br />
                    서울특별시 송파구 올림픽로35 123, 6층(신천동, 향군타워)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default notice6
